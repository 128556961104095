* {
  margin: 0;
  font-family: -apple-system, "Open Sans", sans-serif, "Segoe UI", "Roboto",
    "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  font-style: normal;
  font-weight: 800;
  font-size: 12px;
  line-height: 16px;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

:root {
  --contentWidth: 1130px;
  @media (max-width: 1280px) {
    --contentWidth: 880px;
  }
}
