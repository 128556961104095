.LoginPage {
  overflow: hidden;
  position: relative;
  background: #b9d3d4;
  height: 100vh;
}
.LoginPage-wave {
  background-image: url("../assets/Waves.png");
  background-size: 100% 200px;
  background-position: center bottom;
  background-repeat: no-repeat;
  position: absolute;

  bottom: 136px;
  height: 200px;
  width: 100%;
}

.LoginPage-BottomBar {
  position: absolute;
  background: #002c2e;
  bottom: 0px;
  height: 136px;
  width: 100%;
}

.LoginPage-FormWrapper {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 880px;
  height: 493px;
}

.LoginPage-FormBox {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 56px 32px 56px 50px;
  gap: 10px;

  width: 805px;
  height: 493px;
  box-sizing: border-box;

  background: #e3f6f7;
  border-radius: 16px;
}
.LoginPage-Form {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  gap: 24px;
}

.LoginPage-Hint {
  padding: 0px;
  pointer-events: none;

  position: absolute;
  width: 880px;
  height: 466px;
  left: 0px;
  top: 23px;
}
.LoginPage-Hint img {
  position: absolute;
  top: 0px;
  right: 0px;
}
.LoginPage-Hint-Img {
    -webkit-mask-image: url(../assets/BG_Mask.png);
    mask-image: url(../assets/BG_Mask.png);
    -webkit-mask-repeat: no-repeat;
    mask-repeat: no-repeat;
    mask-size: 432px 466px;
}

.LoginPage .h1 {
  font-family: "Source Serif Pro", sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 52px;
  line-height: 65px;
  display: flex;
  align-items: flex-end;

  /* Primary/700 */

  color: #002c2e;
}
.LoginPage .h2 {
  /* Body/ExtraLarge */
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 36px;
  line-height: 49px;
  /* identical to box height */

  display: flex;
  align-items: flex-end;

  /* Primary/700 */

  color: #002c2e;
}

.LoginPage-Error {
  height: 11px;

  /* Label/Small */

  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-weight: 800;
  font-size: 8px;
  line-height: 11px;
  /* identical to box height */

  display: flex;
  align-items: center;

  /* Red/500 */

  color: #f24c4c;
}
.LoginPage input.LoginPage-Error {
  border-color: #f24c4c;
}
.LoginPage input:focus {
  border-width: 3px;
}
.LoginPage input {
  outline: none !important;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px 24px;
  gap: 10px;

  width: 320px;
  height: 40px;

  background: #e3f6f7;
  color: #004649;

  border: 2px solid #5c999c;
  border-radius: 32px;

  font-family: "Open Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  display: flex;
  align-items: center;
}
.LoginPage button {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0px 32px;

  width: 200px;
  height: 40px;

  /* Primary/500 */

  background: #176e72;
  border-radius: 32px;

  font-family: "Open Sans";
  font-style: normal;
  font-weight: 800;
  font-size: 12px;
  line-height: 16px;
  text-align: center;
  text-transform: uppercase;

  /* Primary/300 */

  color: #b9d3d4;
  border: none;
}

.LoginPage button:hover, .LoginPage button > *:hover {
  filter: brightness(50%);
  -webkit-filter: brightness(50%);
  cursor: pointer;
}

.LoginPage .Label {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 800;
  font-size: 12px;
  line-height: 16px;
  display: flex;
  align-items: center;
  text-transform: uppercase;
  
  /* Primary/700 */
  
  color: #002C2E;
  padding: 0px 0px 4px 10px;
}
