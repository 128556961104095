.HomePage {
  background-image: url("../assets/Main-BG-1920.png");
  background-size: 100% 3514px;
  height: 3284px;
  overflow-x: hidden;
  position: relative;
}

.HomePage-content {
  width: var(--contentWidth);
  margin: 0 auto;
}

.HomePage-topSection {
  width: 100%;
  height: 870px;
  background-image: url("../assets/First_screen_Image.png");
  background-position: 0px 0px;
  background-repeat: no-repeat;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.HomePage-topSection-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0px;
  gap: 0px;
  position: absolute;
  bottom: 0px;
  margin: 0px;
}

.HomePage-NavigationBar {
  box-sizing: border-box;

  /* Auto layout */

  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 12px 32px;
  gap: 16px;

  position: absolute;
  width: 100%;
  height: 64px;
  top: 16px;

  background: rgba(9, 15, 19, 0.9);
  /* Primary/700 */

  border: 1px solid #002c2e;
  border-radius: 106px;
}
.HomePage-NavigationBar-Logo {
  width: 182px;
  height: 48px;
  background-image: url("../assets/Logo.png");
  background-position: 0px 0px;
  background-repeat: no-repeat;
  background-size: stretch;
}
.HomePage-NavigationBar-Buttons {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px;
  gap: 24px;
  color: #b9d3d4;

  text-transform: uppercase;
}

.HomePage-DetailsSection {
  margin-top: 294px;
  width: 100%;
  height: 812px;
  background-image: url("../assets/Features.png");
  background-position: top center;
  background-repeat: no-repeat;

  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.HomePage-DetailsSection-middle-image {
  position: absolute;
  top: 0px;
  left: 50%;
  /* width: 100vw; */
  height: 500px;
  margin: 0px -50vw;
  background-image: url("../assets/middleGroup.png");
  background-position: top center;
  background-repeat: no-repeat;
  background-size: 100% auto;
}

.HomePage-Product {
  margin-top: 109px;
  width: 100%;
  height: 1000px;

  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.HomePage-HomePage-Product-gallery {
  position: relative;
  display: flex;
  flex-direction: row;
  gap: 52px;
  align-items: center;
}

.HomePage-BottomBar {
  position: absolute;
  left: 0px;
  bottom: 0px;

  /* Auto layout */
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 0px 64px;
  gap: 10px;

  height: 137px;
  width: 100%;

  /* Primary/700 */
  background: #002c2e;
  align-items: center;
}

.HomePage-RevertHeader {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.HomePage .HomePage-RevertHeader span {
  color: #002c2e;
  line-height: 60px;
  padding: 0px 16px;
  margin: 15px;
}

button {
  font-weight: 800;
  font-size: 12px;
  line-height: 16px;

  box-sizing: border-box;

  /* Auto layout */
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  width: 154px;
  height: 40px;
}

.join-the-club-full, .HomePage-NavigationBar-Buttons > * {
    -webkit-transition: .4s all;
    transition: .2s all;
}
.join-the-club-full:hover, .HomePage-NavigationBar-Buttons > *:hover {
    filter: brightness(50%);
    -webkit-filter: brightness(50%);
    cursor: pointer;
}

.join-the-club-full {
  /* Yellow/500 */
  background: #f2c94c;
  border-radius: 32px;
  border-width: 0px;

  text-transform: uppercase;
}

.join-the-club-transparent {
  /* Yellow/500 */
  border: 2px solid #f2c94c;
  border-radius: 32px;
  background-color: transparent;

  text-transform: uppercase;
  color: #b9d3d4;
}

.HomePage .h1 {
  font-family: "Source Serif Pro", sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 72px;
  line-height: 90px;
  text-align: center;
  letter-spacing: -0.02em;
  margin: 0px;
  color: #b9d3d4;

  @media (max-width: 1280px) {
    font-size: 52px;
    line-height: 65px;
  }
}
.HomePage .h2 {
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 36px;
  line-height: 49px;
  text-align: center;
  margin: 0px;
  color: #5c999c;

  @media (max-width: 1280px) {
    font-size: 24px;
    line-height: 33px;
  }
}

